'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitPermissions

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitPermissions', [
    'Restangular'
    '$log'
    '_'
    'RestUtils'
    '$timeout'
    '$q'
    (Restangular, $log, _, RestUtils, $timeout, $q) ->
      UnitPermissionsBase = {}
      UnitPermissionsBase.permissions = null
      UnitPermissionsBase.loading = false

      UnitPermissionsBase.get = () ->
        Restangular
          .service 'services/units'
          .one 'permissions'
          .get()

      UnitPermissionsBase.setLoading = (state) ->
        UnitPermissionsBase.loading = state

      UnitPermissionsBase.permissionsLoaded = () ->
        return $q (resolve, reject) ->
          if UnitPermissionsBase.permissions?
            resolve()
          else
            if not UnitPermissionsBase.loading
              UnitPermissionsBase.reloadPermissions()

            $timeout () ->
              UnitPermissionsBase.permissionsLoaded().then () ->
                resolve()
              , (error) ->
                reject(error)
            , 100

      UnitPermissionsBase.reloadPermissions = () ->
        if UnitPermissionsBase.loading
          return

        UnitPermissionsBase.setLoading true

        return $q (resolve, reject) ->
          UnitPermissionsBase.get().then (result) ->
            UnitPermissionsBase.setLoading false

            if result?
              UnitPermissionsBase.permissions = result

            resolve()
          , (error) ->
            reject(error)

      UnitPermissionsBase.loadPermissions = () ->
        if UnitPermissionsBase.permissions?
          return

        UnitPermissionsBase.reloadPermissions()

      UnitPermissionsBase.check = (permission, unitId) ->
        UnitPermissionsBase.loadPermissions()

        return UnitPermissionsBase.permissions[unitId]? and
          (_.indexOf(UnitPermissionsBase.permissions[unitId], permission) > -1)

      UnitPermissionsBase

    ]
